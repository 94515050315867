
import { defineComponent } from "vue-demi";

export default defineComponent({
  emits: ['submit', 'removeModal'],
  name: 'ModalActions',
  props: {
    data: {
      type: Object,
      required: true,
    },
    pending: {
      type: Boolean, 
      required: true,
    }
  },
  data() {
    return {
      date: 0
    }
  },
  computed: {
    isRemoveAction() {
      return this.data.content.action === 'delete';
    }
  },
  methods: {
    submit() {
      if (Date.now() - this.date < 1000) return;
      this.date = Date.now();
      this.$emit('submit');
    }
  }
})
